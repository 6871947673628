import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Menu, 
  X, 
  Check, 
  Bell, 
  Zap, 
  Code, 
  Mail, 
  MapPin, 
  ChevronRight, 
  Link as LinkIcon, 
  RotateCw, 
  MessageSquare, 
  Phone
} from 'lucide-react';

const NavItem = ({ children, targetId }) => {
  const handleClick = (e) => {
    e.preventDefault();
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <a 
      href={`#${targetId}`} 
      onClick={handleClick} 
      className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300 hover:bg-blue-600 hover:bg-opacity-50"
    >
      {children}
    </a>
  );
};

const LoginToolsButton = ({ className = '' }) => (
  <a
    href="https://na-trust.site"
    target="_blank"
    rel="noopener noreferrer"
    className={`
      inline-flex items-center justify-center
      px-6 py-2 border border-transparent
      text-base font-medium rounded-md
      text-white bg-gradient-to-r from-indigo-500 to-blue-600
      hover:from-indigo-600 hover:to-blue-700
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
      transition-all duration-300 ease-in-out
      transform hover:-translate-y-0.5 hover:shadow-lg
      ${className}
    `}
  >
    <span>Login Tools</span>
    <ChevronRight className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
  </a>
);

const GradientButton = ({ children, className = '' }) => (
  <a
    href="https://t.me/DatukEx"
    target="_blank"
    rel="noopener noreferrer"
    className={`inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-8 rounded-full hover:from-blue-600 hover:to-purple-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg text-center ${className}`}
  >
    {children}
  </a>
);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 10);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-gray-900 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-lg' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-white">Nawala Trust Tools</h1>
            {/* <img className="h-10 w-auto" src="/logo.svg" alt="Na-Trust Tools" /> */}
            <div className="hidden md:block ml-10">
              <div className="flex items-baseline space-x-4">
                {['Beranda', 'Fitur', 'Harga', 'Kontak'].map((item) => (
                  <NavItem key={item} targetId={item.toLowerCase()}>{item}</NavItem>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <LoginToolsButton />
            <GradientButton>Daftar Sekarang</GradientButton>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gray-900 bg-opacity-90 backdrop-filter backdrop-blur-lg">
              {['Beranda', 'Fitur', 'Harga', 'Kontak'].map((item) => (
                <NavItem key={item} targetId={item.toLowerCase()}>{item}</NavItem>
              ))}
              <LoginToolsButton className="w-full mt-2 justify-center" />
              <GradientButton className="w-full mt-2">Daftar Sekarang</GradientButton>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

const Feature = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="flex flex-col items-center p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg"
  >
    <div className="bg-gradient-to-br from-blue-400 to-purple-500 p-3 rounded-full mb-4">
      <Icon className="w-8 h-8 text-white" />
    </div>
    <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
    <p className="text-gray-300 text-center">{description}</p>
  </motion.div>
);

const PricingCard = ({ title, price, features, isPopular }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className={`flex flex-col p-6 ${isPopular ? 'bg-gradient-to-br from-blue-500 to-purple-600 border-2 border-yellow-400' : 'bg-white bg-opacity-10'} backdrop-filter backdrop-blur-lg rounded-xl shadow-lg relative overflow-hidden`}
  >
    {isPopular && (
      <div className="absolute top-0 left-0 bg-yellow-400 text-gray-900 text-xs font-bold px-3 py-1 rounded-br-lg">
        POPULER
      </div>
    )}
    <h3 className={`text-2xl font-bold mb-4 ${isPopular ? 'text-white' : 'text-gray-100'}`}>{title}</h3>
    <p className={`text-4xl font-bold mb-6 ${isPopular ? 'text-white' : 'text-blue-400'}`}>
      {price}<span className="text-sm font-normal text-gray-300"> / bulan</span>
    </p>
    <ul className="mb-8">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-3 text-gray-200">
          <Check className="w-5 h-5 text-green-400 mr-2" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <GradientButton className={`mt-auto ${isPopular ? 'bg-white text-blue-600 hover:bg-gray-100' : ''}`}>
      Pilih Paket
    </GradientButton>
  </motion.div>
);

export default function NawalaCheckerWebsite() {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white min-h-screen">
      <Navbar />

      <header id="beranda" className="relative overflow-hidden pt-20 pb-40 md:pt-32 md:pb-72">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-transparent z-10"></div>
          <video
            autoPlay
            loop
            muted
            className="w-full h-full object-cover object-center"
          >
            <source src="/videos/cyber-security-bg.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-7xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
              NawalaChecker: Pengecekan Domain anda dari Nawala Secara Real-Time
            </h1>
            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Memastikan domain Anda aman dari nawala Kominfo dengan pengecekan cepat, akurat, dan real-time.
            </p>
            <div className="mt-10 flex flex-col sm:flex-row items-center gap-4">
              <GradientButton>
                Daftar Sekarang <ChevronRight className="inline-block ml-2" />
              </GradientButton>
              <a
                href="#fitur"
                className="text-lg font-semibold text-gray-300 hover:text-white transition duration-300 ease-in-out flex items-center"
              >
                Pelajari Lebih Lanjut <ChevronRight className="ml-2" />
              </a>
            </div>
          </motion.div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <svg
            className="w-full h-24 fill-current text-gray-800"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path d="M0 100 C 20 0 50 0 100 100 Z" />
          </svg>
        </div>
      </header>

      <main>
        <section id="fitur" className="py-20 bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
                Fitur Utama
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <Feature 
                  icon={Zap} 
                  title="Cepat & Akurat" 
                  description="Proses pengecekan dilakukan secara real-time, memastikan hasil yang cepat dan tepat."
                />
                <Feature 
                  icon={Code} 
                  title="Integrasi API" 
                  description="Kami menyediakan API yang dapat diintegrasikan langsung ke dalam sistem Anda untuk kemudahan penggunaan."
                />
                <Feature 
                  icon={Bell} 
                  title="Notifikasi Otomatis" 
                  description="Dapatkan notifikasi langsung jika domain Anda terkena nawala, sehingga Anda bisa segera mengambil tindakan."
                />
                <Feature 
                  icon={LinkIcon} 
                  title="Short Domain URL" 
                  description="Perpendek URL domain Anda untuk kemudahan sharing dan tracking."
                />
                <Feature 
                  icon={RotateCw} 
                  title="URL Rotator Domain" 
                  description="Otomatis mengganti domain yang terkena nawala dengan domain yang aman."
                />
                <Feature 
                  icon={MessageSquare} 
                  title="Konsultasi Gratis" 
                  description="Dapatkan saran ahli melalui sesi konsultasi gratis untuk mengoptimalkan keamanan domain Anda."
                />
              </div>
            </motion.div>
          </div>
        </section>

        <section id="harga" className="py-20 bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
                Paket Harga
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <PricingCard 
                  title="Paket Basic" 
                  price="Rp1.500.000" 
                  features={[
                    "Pengecekan hingga 50 domain",
                    "Notifikasi real-time",
                    "Short Domain URL (100 links/bulan)",
                    "Free trial 3 hari",
                    "1 sesi konsultasi gratis"
                  ]}
                />
<PricingCard 
                  title="Paket Pro" 
                  price="Rp2.500.000" 
                  features={[
                    "Pengecekan hingga 100 domain",
                    "Notifikasi real-time",
                    "Short Domain URL (500 links/bulan)",
                    "URL Rotator Domain (5 rotasi)",
                    "Free trial 3 hari",
                    "2 sesi konsultasi gratis"
                  ]}
                  isPopular={true}
                />
                <PricingCard 
                  title="Paket Enterprise" 
                  price="Rp3.500.000" 
                  features={[
                    "Pengecekan hingga 150 domain",
                    "Notifikasi real-time",
                    "Short Domain URL (unlimited)",
                    "URL Rotator Domain (unlimited)",
                    "Free trial 3 hari",
                    "4 sesi konsultasi gratis",
                    "Dukungan prioritas 24/7"
                  ]}
                />
              </div>
              <div className="mt-12 bg-gradient-to-r from-blue-500 to-purple-600 rounded-xl p-8 text-center">
                <h3 className="text-2xl font-bold mb-4">Penambahan Domain</h3>
                <p className="text-xl mb-2">Setiap penambahan 50 domain: +Rp1.000.000 / bulan</p>
                <p>Tambahkan domain sesuai kebutuhan Anda dengan biaya yang fleksibel.</p>
              </div>
            </motion.div>
          </div>
        </section>

        <section id="kontak" className="py-20 bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-extrabold mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
                Hubungi Kami
              </h2>
              <GradientButton>
                Chat Admin di Telegram
              </GradientButton>
              <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="flex flex-col items-center p-6 bg-white bg-opacity-5 rounded-xl">
                  <div className="bg-gradient-to-br from-blue-400 to-purple-500 p-3 rounded-full mb-4">
                    <Phone className="w-8 h-8 text-white" />
                  </div>
                  <a 
                    href="https://t.me/DatukEx" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="font-semibold text-white hover:text-blue-400 transition-colors duration-300 flex items-center"
                  >
                    <span>Chat di Telegram</span>
                  </a>
                </div>
                <div className="flex flex-col items-center p-6 bg-white bg-opacity-5 rounded-xl">
                  <div className="bg-gradient-to-br from-blue-400 to-purple-500 p-3 rounded-full mb-4">
                    <Mail className="w-8 h-8 text-white" />
                  </div>
                  <p className="font-semibold">admin@backoffice-nawala.com</p>
                </div>
                <div className="flex flex-col items-center p-6 bg-white bg-opacity-5 rounded-xl">
                  <div className="bg-gradient-to-br from-blue-400 to-purple-500 p-3 rounded-full mb-4">
                    <MapPin className="w-8 h-8 text-white" />
                  </div>
                  <p className="font-semibold">Jakarta, Indonesia</p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
<div className="md:col-span-2">
  <div className="flex flex-col items-start">
    <img src="/logo.svg" alt="Na-Trust Tools" className="h-12 w-auto mb-2" />
    <span className="text-sm font-medium text-gray-400 mb-4">Domain Checker</span>
  </div>
  <p className="text-gray-400 text-sm mb-4">
    Solusi terpercaya untuk memastikan keamanan domain Anda dari Nawala Kominfo. Kami menyediakan layanan pengecekan cepat, akurat, dan real-time untuk melindungi aset digital Anda.
  </p>
</div>
            <div>
              <h4 className="text-white font-semibold mb-4">Tautan Cepat</h4>
              <ul className="space-y-2">
                <li><a href="#beranda" className="text-gray-400 hover:text-white transition-colors duration-300">Beranda</a></li>
                <li><a href="#fitur" className="text-gray-400 hover:text-white transition-colors duration-300">Fitur</a></li>
                <li><a href="#harga" className="text-gray-400 hover:text-white transition-colors duration-300">Harga</a></li>
                <li><a href="#kontak" className="text-gray-400 hover:text-white transition-colors duration-300">Kontak</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-white font-semibold mb-4">Ikuti Kami</h4>
              <div className="flex space-x-4">
                <a href="https://t.me/DatukEx" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors duration-300">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.654-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-700 pt-8 text-center">
            <p className="text-gray-400 text-sm">
              &copy; {new Date().getFullYear()} Na-Trust: Pengecekan Domain anda dari Nawala Secara Real-Time. Semua Hak Dilindungi.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}